import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const charter = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Charter" />
      <div class="mr-auto scrollmenu_container">
        <hr/>
        <a href='#charter_s0' class="scrollmenu_nav">Our Aims & Promises</a>
        <hr/>
        <a href='#charter_s1' class="scrollmenu_nav">SHARED FUTURE</a>
        <hr/>
        <a href='#charter_s2' class="scrollmenu_nav">FIXING OUR POLITICS</a>
        <hr/>
        <a href='#charter_s3' class="scrollmenu_nav">HEALTH & SAFETY</a>
        <hr/>
        <a href='#charter_s4' class="scrollmenu_nav">MENTAL HEALTH</a>
        <hr/>
      </div>
      <div class="charter_text ml_auto">
        <h1>Charter</h1>
        <div class="charter_section" id='charter_s0'>
          <h2>Our Aims & Promises</h2>
          <p>Using the policy areas highlighted on this Charter we at Progressive Politics NI will seek to build a progressive movement in Northern Ireland to fight for equality and social justice by carrying out the following; 
            <li>Working with & supporting progressive politicians, parties, activists & governments who seek equality & social justice </li>
            <li>Fostering cross-party cooperation to achieve progressive objectives </li>
            <li>Work with organisations & lobbying groups who are working on securing legislative change on human rights of equality & social justice </li>
            <li>Setup groups region-wide to tackle inequality & social justice issues all over NI </li>
            <li>Oppose oppressive politicians, parties & governments </li>
          </p>
        </div>
        <div class="charter_section" id='charter_s1'>
            <h2>SHARED FUTURE</h2>
            <p>Integrated education</p>
            <p>Mixed communities</p>
            <p>Shared public spaces</p>
            <p>Commissions to resolve contentious issues (flags, parading, the past)</p>
            <p>No paramilitary flags. symbolism or territory marking</p>
          </div>
        <div class="charter_section" id='charter_s2'>
          <h2>FIXING OUR POLITICS</h2>
          <p>Reassess the terms of the Good Friday Agreement especially in areas surrounding: </p>
          <li>Abolishing or reforming the Petition of Concern.</li>
          <li>Ridding the Assembly of divisive Unionist/Nationalist designations</li>
          <li>Doing away with mandatory coalition </li>
          <p>End party donor secrecy</p>
          <p>16 & 17 year olds the right to vote</p>
          <p>Reform the House of Lords so Lords are elected by the public & not chosen by political parties</p>
        </div>
        <div class="charter_section" id='charter_s3'>
          <h2>HEALTH & SAFETY</h2>
          <p>Properly funded health service</p>
          <p>Plans to address the problems in Emergency Departments and Waiting Lists in Hospitals</p>
          <p>No to privatisation of our NHS</p>
          <p>Greater attention paid to & reform of the services for those with disabilities</p>
          <p>More resources to tackle the issue of human trafficking</p>
          <p>Improvement of support services for refugees & migrants</p>
        </div>
        <div class="charter_section" id='charter_s4'>
          <h2>MENTAL HEALTH</h2>
          <p>Restructure Mental Health Services</p>
          <p>Additional Funding for Mental Health Services </p>
          <p>Suicide Prevention Strategy </p>
          <p>Stormont to implement the Mental Capacity Act </p>
          <p>All workplaces to have Mental Health First Aiders </p>
          <p>Greater education of mental health issues in schools </p>
        </div>


      </div>
    </Layout>
  )
}

export default charter

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
